import { useState } from "react";
import classes from "./listing-creator.module.css";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { createListing, downloadEbayTemplate } from "../utils/data";
import { uploadImages } from "../utils/storage";

const emptyListing = {
  brand: "",
  model: "",
  year: "",
  price: 0,
  description: "",
  box: false,
  papers: false,
  working: false,
  sold: false,
  mainImage: "",
  images: []
}

export default function ListingCreator() {
  const [uploading, setUploading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signedIn, setSignedIn] = useState(false);
  const [listing, setListing] = useState(emptyListing);
  const [downloadTemplate, setDownloadTemplate] = useState(true);

  function updateListing(newData) {
    setListing((prevData) => {
      return {
        ...prevData,
        ...newData
      }
    })
  }

  function handleMainImageChanged(ev) {
    const imageUrl = URL.createObjectURL(ev.currentTarget.files[0]);
    updateListing({ mainImage: imageUrl });
  }

  function handleImagesChanged(ev) {
    const files = ev.currentTarget.files;
    let imageUrls = [];
    for (let i = 0; i < files.length; i++) {
      const imageUrl = URL.createObjectURL(files[i]);
      imageUrls.push(imageUrl);
    }
    updateListing({ images: imageUrls });
  }

  async function publishListing() {
    const id = `${listing.brand}-${listing.model}-${new Date().getTime()}`.replaceAll(" ", "-");
    setUploading(true);
    const mainImageUrl = await uploadImages(id, [listing.mainImage]);
    const otherImageUrls = await uploadImages(id, listing.images);
    const fullListing = {
      ...listing, 
      id, 
      mainImage: mainImageUrl[0],
      images: otherImageUrls
    }
    const res = await createListing(fullListing);
    if (res === "success") {
      if (downloadTemplate) {
        await downloadEbayTemplate(fullListing);
      }
      window.alert("Successfully published!");
      setListing(emptyListing);
    } else {
      window.alert("Something went wrong!");
    }
    setUploading(false);
  }

  function handleSignIn(e) {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSignedIn(true);
        setEmail("");
        setPassword("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSignOut() {
    signOut(auth).then(() => {
      console.log("Signed out successfully");
      setSignedIn(false);
    }).catch((error) => {
      console.log(error);
    });
  }

  if (!signedIn) {
    return (
      <form className={classes.loginForm}>
        <input
          type="email"
          required
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          required
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSignIn}>SIGN IN</button>
      </form>
    );
  }

  return (
    <div className={classes.container}>
      <button onClick={handleSignOut} className={classes.signOutButton}>Sign out</button>
      <a href="https://www.remove.bg/upload" target="_blank" rel="noreferrer">Remove background for main image here</a>
      <a href="https://cloudconvert.com/png-to-webp" target="_blank" rel="noreferrer">Convert main image to webp format here</a>
      <div className={classes.uploader}>
        <h1>Create a listing</h1>
        <table>
          <tbody>
            <tr>
              <th>Brand</th>
              <td>
                <input
                  type="text"
                  value={listing.brand}
                  onChange={(ev) => updateListing({ brand: ev.target.value })}
                />
              </td>
            </tr>
            <tr>
              <th>Model</th>
              <td>
                <input
                  type="text"
                  value={listing.model}
                  onChange={(ev) => updateListing({ model: ev.target.value })}
                />
              </td>
            </tr>
            <tr>
              <th>Year</th>
              <td>
                <input
                  type="text"
                  value={listing.year}
                  onChange={(ev) => updateListing({ year: ev.target.value })}
                />
              </td>
            </tr>
            <tr>
              <th>Price</th>
              <td>
                <input
                  type="number"
                  value={listing.price === 0 ? "" : listing.price}
                  onChange={(ev) => updateListing({ price: +ev.target.value })}
                />
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <textarea
                  value={listing.description}
                  onChange={(ev) => updateListing({ description: ev.target.value })}
                />
              </td>
            </tr>
            <tr>
              <th>Box?</th>
              <td>
                <input
                  type="checkbox"
                  checked={listing.box}
                  onChange={(ev) => updateListing({ box: ev.target.checked })}
                />
              </td>
            </tr>
            <tr>
              <th>Papers?</th>
              <td>
                <input
                  type="checkbox"
                  checked={listing.papers}
                  onChange={(ev) => updateListing({ papers: ev.target.checked })}
                />
              </td>
            </tr>
            <tr>
              <th>Working?</th>
              <td>
                <input
                  type="checkbox"
                  checked={listing.working}
                  onChange={(ev) => updateListing({ working: ev.target.checked })}
                />
              </td>
            </tr>
            <tr>
              <th>Sold?</th>
              <td>
                <input
                  type="checkbox"
                  checked={listing.sold}
                  onChange={(ev) => updateListing({ sold: ev.target.checked })}
                />
              </td>
            </tr>
            <tr>
              <th>Main Image (background removed)</th>
              <td>
                <input type="file" onChange={handleMainImageChanged} accept=".webp,.png"/>
              </td>
            </tr>
            <tr>
              <th>Images</th>
              <td>
                <input type="file" multiple onChange={handleImagesChanged} accept=".jpg,.jpeg,.png,.webp" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.imageViewer}>
        <h3>Main Image preview</h3>
        {listing.mainImage !== "" ? (
          <img src={listing.mainImage} className={classes.mainImage} alt="watch" />
        ) : (
          <p>No image uploaded</p>
        )}
        <h3>Other Images preview</h3>
        {listing.images.length > 0 ? (
          listing.images.map((image, idx) => {
            return <img src={image} className={classes.otherImage} key={idx} alt="watch" />;
          })
        ) : (
          <p>No images uploaded</p>
        )}
      </div>
      <table style={{marginTop: "1rem"}}>
        <tbody>
          <tr>
            <th>Download ebay template?</th>
            <td>
              <input
                type="checkbox"
                checked={downloadTemplate}
                onChange={(ev) => setDownloadTemplate(ev.target.checked)}
                style={{transform: "scale(1.5)", marginLeft: "0.8rem"}}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
          disabled={uploading}
          className={classes.uploadButton}
          onClick={publishListing}
        >
          {uploading ? "Publishing..." : "Publish"}
        </button>
    </div>
  );
}
