import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import classes from './images-modal.module.css';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState } from 'react';

export default function ImagesModal({open, setOpen, productName, images}) {
    const [imageIdx, setImageIdx] = useState(0);
    const numImages = images.length;

    function handleClose() {
        setOpen(false);
    }

    function handleClickLeft() {
        setImageIdx(imageIdx === 0 ? numImages - 1 : (imageIdx - 1) % numImages);
    }

    function handleClickRight() {
        setImageIdx((imageIdx + 1) % numImages);
    }

    return <Modal open={open} onClose={handleClose}>
        <Box className={classes.modal}>
            <ClearIcon onClick={handleClose} className={classes.closeIcon} />
            <div className={classes.galleryContainer}>
                <div className={classes.leftButton} onClick={handleClickLeft}>
                    <ChevronLeftIcon className={classes.arrow} />
                </div>
                <div className={classes.imageContainer}>
                    <img 
                        src={images[imageIdx]} 
                        alt={`${productName} ${imageIdx + 1}`}
                        className={classes.image} 
                    />
                </div>
                <div className={classes.rightButton} onClick={handleClickRight}>
                    <ChevronRightIcon className={classes.arrow} />
                </div>
            </div>
        </Box>
    </Modal>
}