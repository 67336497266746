import { db } from "../firebase";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import Mustache from "mustache";

export default async function refreshData() {
  const products = {};
  const productsSnapshot = await getDocs(collection(db, "products"));
  productsSnapshot.forEach((doc) => {
    products[doc.id] = doc.data();
  });
  return { products };
}

export async function getProduct(productID) {
  const docRef = doc(db, "products", productID);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function createListing(listingData) {
  const docRef = doc(db, "products", listingData.id);
  try {
    await setDoc(docRef, listingData);
    return "success";
  } catch {
    return "failed";
  }
}

export async function getTemplate(templateName) {
  const docRef = doc(db, "templates", templateName);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function downloadEbayTemplate(listing) {
  const data = {
    ...listing,
    box: listing.box ? "Yes" : "No",
    papers: listing.papers ? "Yes" : "No",
    working: listing.working ? "Yes" : "No",
    images: listing.images.map(img => {
      return { url: img }
    }),
  }
  const template = await getTemplate("ebay");
  const htmlString = Mustache.render(template.html, data);
  const anchor = document.createElement('a');
  anchor.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(htmlString));
  anchor.setAttribute('download', `${listing.brand.replaceAll(" ", "_")}_${listing.model.replaceAll(" ", "_")}_template.html`);
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}