import classes from './sitemap.module.css';

export default function Sitemap() {
  return <div className={classes.container}>
    <h1>SITEMAP</h1>
    <a href={"https://henrycawleywatches.co.uk/"}>Home</a>
    <a href={"https://henrycawleywatches.co.uk/contact"}>Contact us</a>
    <a href={"https://henrycawleywatches.co.uk/cookies"}>Cookies</a>
    <a href={"https://henrycawleywatches.co.uk/privacy"}>Privacy</a>
    <a href={"https://henrycawleywatches.co.uk/sell"}>Sell to us</a>
    <a href={"https://henrycawleywatches.co.uk/sold"}>Sold watches</a>
    <a href={"https://henrycawleywatches.co.uk/terms"}>Terms and conditions</a>
  </div>
}