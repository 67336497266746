import classes from './product-gallery.module.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContext, useState } from 'react';
import { DataContext } from '../app';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { formatAsMoney } from '../utils/formatting';

const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 200 : -200,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 200 : -200,
        opacity: 0
      };
    }
};

export default function ProductGallery() {
    const navigate = useNavigate();
    const data = useContext(DataContext);
    const products = data.products;
    const productIds = Object.keys(products).filter((id) => {
        return !products[id].sold;
    });
    const [ selectedProductIdx, setSelectedProductIdx ] = useState(0);
    const selectedProduct = products[productIds[selectedProductIdx]];
    const numProducts = productIds.length;
    const [ direction, setDirection ] = useState(-1);

    function handleClickLeft() {
        setDirection(-1);
        setSelectedProductIdx(selectedProductIdx === 0 ? numProducts - 1 : (selectedProductIdx - 1) % numProducts);
    }

    function handleClickRight() {
        setDirection(1);
        setSelectedProductIdx((selectedProductIdx + 1) % numProducts);
    }

    if (productIds.length === 0) {
        return <p className={classes.noWatchesMessage}>Looks like we don't have any watches for sale at the moment. Check back soon!</p>
    }

    function goToSelectedProduct() {
        navigate(`product/${productIds[selectedProductIdx]}`)
    }

    return <div className={classes.container}>
        <div className={classes.nameAndPrice} onClick={goToSelectedProduct}>
            <h1>{`${selectedProduct.brand.toUpperCase()} ${selectedProduct.model.toUpperCase()}`}</h1>
            <h2>£{formatAsMoney(selectedProduct.price)}</h2>
        </div>
        <div className={classes.galleryContainer}>
            <div className={classes.gallery}>
                <div className={classes.leftButton} onClick={handleClickLeft}>
                    <ChevronLeftIcon className={classes.arrow} />
                </div>
                <div className={classes.imageContainer}>
                    <AnimatePresence initial={true} custom={direction}>
                        <motion.img 
                            src={selectedProduct.mainImage} 
                            alt={`${selectedProduct.brand} ${selectedProduct.model}`}
                            custom={direction} 
                            variants={variants}
                            className={classes.productImage} 
                            initial="enter"
                            animate="center"
                            exit="exit"
                            whileHover={{ scale: 1.05 }} 
                            key={selectedProductIdx} 
                            onClick={goToSelectedProduct}
                            whileTap={{ scale: 0.95 }}
                        />
                    </AnimatePresence>
                </div>
                <div className={classes.rightButton} onClick={handleClickRight}>
                    <ChevronRightIcon className={classes.arrow} />
                </div>
            </div>
        </div>
        <div className={classes.shadow} />
    </div>
}