import classes from "./banner.module.css";
import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <div className={classes.container}>
      <ul>
        <li>
          <Link to="sell">SELL TO US</Link>
        </li>
        <li>
          <Link to="contact">CONTACT US</Link>
        </li>
      </ul>
    </div>
  );
}
