import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getProduct } from "../utils/data";
import classes from "./product.module.css";
import { motion, AnimatePresence } from "framer-motion";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { ImagesModal, OfferModal, EnquireModal } from "../components";
import CircularProgress from "@mui/material/CircularProgress";
// import { stripeCheckout } from "../utils/stripe";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatAsMoney } from "../utils/formatting";

const variants = (direction) => {
  return {
    enter: {
      x: 200 * direction,
      opacity: 0,
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: {
      zIndex: 0,
      x: 200 * direction,
      opacity: 0,
    },
  };
};

export default function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sold, setSold] = useState(false);
  const [imagesModalOpen, setImagesModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [enquireModalOpen, setEnquireModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getProduct(id)
        .then((res) => {
          setProduct(res);
          setLoading(false);
          setError(false);
          setSold(res.sold);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  }, [id]);

  // async function handleBuyNow() {
  //   setLoading(true);
  //   const prod = await getProduct(id);
  //   if (prod.sold) {
  //     setLoading(false);
  //     setSold(true);
  //     return;
  //   }
  //   const productData = {
  //     id,
  //     name: `${product.brand} ${product.model} - ${product.year}`,
  //     price: product.price,
  //   };
  //   await stripeCheckout(productData);
  // }

  if (loading) {
    return <div><CircularProgress /></div>;
  }

  if (error) {
    return <div>Whoops! Something went wrong, please try again.</div>;
  }

  if (sold) {
    return <div>Sorry, it looks like this product has already been sold!</div>;
  }

  return (
    <div className={classes.container}>
      <ArrowBackIcon className={classes.backArrow} onClick={() => navigate("/")} />
      <div className={classes.watch}>
        <div className={classes.imageContainer}>
          <AnimatePresence initial={true}>
            <motion.img
              variants={variants(1)}
              initial="enter"
              animate="center"
              exit="exit"
              src={product.mainImage}
              alt={`${product.brand} ${product.model}`}
              className={classes.productImage}
              whileHover={{ scale: 1.05 }}
              key={id}
              whileTap={{ scale: 0.95 }}
              onClick={() => setImagesModalOpen(true)}
            />
          </AnimatePresence>
        </div>
        <div className={classes.shadow} />
      </div>
      <AnimatePresence initial={true}>
        <motion.div
          className={classes.detailsContainer}
          variants={variants(-1)}
          initial="enter"
          animate="center"
          exit="exit"
          key="details"
        >
          <h1>
            {product.brand.toUpperCase()} {product.model.toUpperCase()}
          </h1>
          <h4>{product.year}</h4>
          <h2>£{formatAsMoney(product.price)}</h2>
          <p className={classes.shipping}>(Free shipping - <Link to="/terms">Free returns</Link>)</p>
          <p className={classes.description}>{product.description}</p>
          <span className={classes.boxAndPapers}>
            <p>Box</p>
            {product.box ? <DoneIcon className={classes.icon} /> : <ClearIcon className={classes.icon} />}
            <p>Papers</p>
            {product.papers ? <DoneIcon className={classes.icon} /> : <ClearIcon className={classes.icon} />}
            <p>Working</p>
            {product.working ? <DoneIcon className={classes.icon} /> : <ClearIcon className={classes.icon} />}
          </span>
          <h1 className={classes.buyNow} onClick={() => setEnquireModalOpen(true)}>
            {/* BUY NOW */}
            ENQUIRE
          </h1>
          <span className={classes.makeOfferContainer}>
            <p>Or</p>
            <p className={classes.makeOffer} onClick={() => setOfferModalOpen(true)}>
              make an offer
            </p>
          </span>
        </motion.div>
      </AnimatePresence>
      <ImagesModal open={imagesModalOpen} setOpen={setImagesModalOpen} productName={`${product.brand} ${product.model}`} images={product.images} />
      <EnquireModal open={enquireModalOpen} setOpen={setEnquireModalOpen} product={product} />
      <OfferModal open={offerModalOpen} setOpen={setOfferModalOpen} product={product} />
    </div>
  );
}
