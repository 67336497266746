import classes from './sell-to-us.module.css';
import { TextInput } from '../components';
import { useState } from 'react';
import { sendInternalMail, isValidEmail } from '../utils/mail';
import { CircularProgress } from '@mui/material';

export default function SellToUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [watchDetails, setWatchDetails] = useState("");
  const [sendStatus, setSendStatus] = useState("not-sent");
  const [error, setError] = useState(null);

  async function handleSubmit() {
    if (!isValidData()) {
      return;
    }
    setSendStatus("sending");
    const data = { subject: "Watch for sale", name, email, phone, watchDetails };
    const res = await sendInternalMail(data);
    if (res.data) { 
      setSendStatus("sent");
      resetForm();
    } else { 
      setSendStatus("not-sent");
      setError("Something went wrong submitting your message, please try again");
    };
  }

  function isValidData() {
    switch (true) {
      case (name.length > 50 || name.length < 2):
        setError("Please enter a valid name");
        return false;
      case (!isValidEmail(email)):
        setError("Please enter a valid email address");
        return false;
      case (phone.length > 20 || phone.length < 6):
        setError("Please enter a valid phone number");
        return false;
      case (watchDetails.length > 1000):
        setError("Your description is too long, please make it a bit shorter");
        return false;
      case (watchDetails.length < 5):
        setError("Please provide a bit more information about your watch");
        return false;
      default: 
        setError(null);
        return true;
    }
  }

  function resetForm() {
    setName("");
    setEmail("");
    setPhone("");
    setWatchDetails("");
  }

  if (sendStatus === "sent") {
    return <div className={classes.container}>
      <h1 className={classes.thanksTitle}>Thanks for your message!</h1>
      <p className={classes.thanksMessage}>Thanks for getting in touch. We will get back to you as soon as possible.</p>
    </div>
  }

  return <div className={classes.container}>
      <TextInput title="NAME" type="text" className={classes.textInput} value={name} onChange={(ev) => setName(ev.target.value)} />
      <TextInput title="EMAIL" type="text" className={classes.textInput} value={email} onChange={(ev) => setEmail(ev.target.value)} />
      <TextInput title="PHONE" type="text" className={classes.textInput} value={phone} onChange={(ev) => setPhone(ev.target.value)} />
      <TextInput title="DETAILS OF YOUR WATCH" type="text-area" className={classes.areaInput} value={watchDetails} onChange={(ev) => setWatchDetails(ev.target.value)} />
      { error && <p className="error">{error}</p> }
      { sendStatus === "sending" ? 
        <div className={classes.submit}><CircularProgress /></div> :
        <h1 className={classes.submit} onClick={handleSubmit}>SUBMIT</h1> }
    </div>;
}
