import { Link } from 'react-router-dom';
import classes from './terms.module.css';

export default function Terms() {
  return <div className={classes.container}>
      <h2>Returns Policy</h2>
      <p>Items can be returned within 14 days of receipt.</p>
      <p>To arrange a return please contact us using <Link to="/contact" className={classes.link}>this form</Link></p>
    </div>;
}
