import classes from "./text-input.module.css";

export default function TextInput({title, type, ...props}) {
  return <div className={`${classes.container} ${props.className}`}>
      <h2 className={classes.title}>{title}</h2>
      { type === "text" ?
        <input type="text" className={classes.input} onChange={props.onChange} value={props.value} />
        :
        <textarea className={classes.areaInput} onChange={props.onChange} value={props.value} rows={props.rows} />
      }
    </div>
}