import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import classes from "./enquire-modal.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { formatAsMoney } from "../utils/formatting";
import { TextInput } from ".";
import { sendInternalMail, isValidEmail } from "../utils/mail";
import { CircularProgress } from "@mui/material";

export default function EnquireModal({ open, setOpen, product }) {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ message, setMessage ] = useState();
  const [ sendStatus, setSendStatus ] = useState("not-sent");
  const [ error, setError ] = useState(null);

  function handleClose() {
    setOpen(false);
    resetForm();
    setSendStatus("not-sent");
  }

  async function handleSubmit() {
    if (!isValidData()) {
      return;
    }
    setSendStatus("sending");
    const data = { 
      subject: `Enquiry: ${product.brand} ${product.model}`, 
      watchId: product.id, 
      name, 
      email, 
      phone, 
      message
    };
    const res = await sendInternalMail(data);
    if (res.data) { 
      setSendStatus("sent");
      resetForm();
    } else { 
      setSendStatus("not-sent");
      setError("Something went wrong submitting your message, please try again");
    };
  }

  function isValidData() {
    switch (true) {
      case (name.length > 50 || name.length < 2):
        setError("Please enter a valid name");
        return false;
      case (!isValidEmail(email)):
        setError("Please enter a valid email address");
        return false;
      case (phone.length > 20 || phone.length < 6):
        setError("Please enter a valid phone number");
        return false;
      case (message.length > 1000):
        setError("Your message is too long, please make it a bit shorter");
        return false;
      case (message.length < 1):
        setError("Please enter a message");
        return false;
      default: 
        setError(null);
        return true;
    }
  }

  function resetForm() {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setError(null);
  }

  let modalContent;

  if (sendStatus === "sent") {
    modalContent = <div className={classes.thanks}>
      <h1 className={classes.thanksTitle}>Enquiry sent!</h1>
      <p className={classes.thanksMessage}>Thanks for getting in touch. We will review your enquiry and get back to you as soon as possible.</p>
    </div>
  } else {
    modalContent = <>
      <h1>ENQUIRE</h1>
      <h2>{product.brand.toUpperCase()} {product.model.toUpperCase()}</h2>
      <h4>{product.year}</h4>
      <h3>£{formatAsMoney(product.price)}</h3>
      <TextInput title="NAME" type="text" className={classes.textInput} onChange={(ev) => setName(ev.target.value)} />
      <TextInput title="EMAIL" type="text" className={classes.textInput} onChange={(ev) => setEmail(ev.target.value)} />
      <TextInput title="PHONE" type="text" className={classes.textInput} onChange={(ev) => setPhone(ev.target.value)}/>
      <TextInput title="MESSAGE" type="text" className={classes.textInput} onChange={(ev) => setMessage(ev.target.value)}/>
      { error && <p className="error">{error}</p> }
      { sendStatus === "sending" ? 
        <div className={classes.submit}><CircularProgress /></div> :
        <h1 className={classes.submit} onClick={handleSubmit}>SUBMIT</h1> }
    </>
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modal}>
        <ClearIcon onClick={handleClose} className={classes.closeIcon} />
        { modalContent }
      </Box>
    </Modal>
  );
}
