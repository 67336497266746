import { useEffect, useState, createContext } from "react";
import classes from "./app.module.css";
import { Home, Product, ContactUs, SellToUs, Terms, Success, Sold, Cookies, Privacy, Sitemap, ListingCreator } from "./routes";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import refreshData from "./utils/data";
import logo from "./assets/logo-dark-theme.svg";
import { Banner } from "./components";
import { CircularProgress } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { motion, AnimatePresence } from 'framer-motion';

const footerVariants = {
  "hidden": {
      y: 15,
      opacity: 0,
  },
  "visible": {
      y: 0,
      opacity: 1,
  },
}

export const DataContext = createContext({});

function App() {
  const [ showFooter, setShowFooter ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    refreshData()
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  function toggleFooter() {
    setShowFooter(!showFooter);
  }

  if (error) {
    return <div className={classes.container}>Whoops! Something went wrong, please try again.</div>;
  }

  return (
    <DataContext.Provider value={data}>
      <div className={classes.container}>
        <Banner />
        {/* Burger Navbar */}
        <div className={classes.logoContainer} onClick={() => navigate("/")}>
          <img src={logo} alt="Henry Cawley Watches" className={classes.logo} />
        </div>
        <div className={classes.routesContainer}>
          { loading || !data ? 
            <CircularProgress /> :
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="product/:id" element={<Product />} />
              <Route path="contact" element={<ContactUs />} />
              <Route path="sell" element={<SellToUs />} />
              <Route path="terms" element={<Terms />} />
              <Route path="success" element={<Success />} />
              <Route path="sold" element={<Sold />} />
              <Route path="cookies" element={<Cookies />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="sitemap" element={<Sitemap />} />
              <Route path="admin" element={<ListingCreator />} />
            </Routes> 
          }
        </div>
        <MoreHorizIcon className={classes.moreIcon} onClick={toggleFooter} />
        <AnimatePresence initial={false}>
          { showFooter && <motion.div
            variants={footerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="footer"
            className={classes.footer}
          >
            <div className={classes.links}>
                <Link to="/sold" className={classes.link}>Sold products</Link>
                <Link to="/contact" className={classes.link}>Return an item</Link>
                <Link to="/terms" className={classes.link}>Returns policy</Link>
                <Link to="/privacy" className={classes.link}>Privacy</Link>
                <Link to="/cookies" className={classes.link}>Cookies</Link>
                <Link to="/sitemap" className={classes.link}>Sitemap</Link>
            </div>
            <CloseIcon className={classes.closeIcon} onClick={toggleFooter} />
          </motion.div> }
        </AnimatePresence>
      </div>
    </DataContext.Provider>
  );
}

export default App;
