import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UUID } from ".";

export async function uploadImages(productId, localUrls) {
  const remoteUrls = [];
  for (let i = 0; i < localUrls.length; i++) {
    const imageUrl = localUrls[i];
    const blob = await getBlobFromUrl(imageUrl);
    const storagePath = `products/${productId}/${UUID()}`;
    const downloadUrl = await uploadBlob(blob, storagePath);
    remoteUrls.push(downloadUrl);
  }
  return remoteUrls;
}

export async function getBlobFromUrl(url) {
  const res = await fetch(url);
  const blob = await res.blob();
  return blob;
}

export async function uploadBlob(blob, storagePath) {
  const storageRef = ref(storage, storagePath);
  await uploadBytes(storageRef, blob);
  const downloadUrl = await getDownloadURL(storageRef);
  return downloadUrl;
}