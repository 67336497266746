import classes from './cookies.module.css';

export default function Cookies() {
  return <div className={classes.container}>
      <h1>COOKIES</h1>
      <p>Our website ("Site") uses cookies to distinguish you from other users of the Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site. By continuing to browse the site, you are agreeing to our use of cookies.</p>
      <p>A cookie is a small file of letters and numbers that we store on your browser, or the hard drive of your device if you agree. Cookies contain information that is transferred to your device’s hard drive.</p>
      <p>We use the following cookies:</p>
      <ul>
        <li>
          Strictly necessary cookies. These are cookies that are required for the operation of our Site.
        </li>
        <li>
          Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.
        </li>
        <li>
          Functionality cookies. These are used to recognise you when you return to our Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
        </li>
        <li>
          Targeting cookies. These cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
        </li>
      </ul>
      <p>You can find more information about the individual cookies we use and the purposes for which we use them in the table below:</p>
      <table>
        <tr>
          <th>Cookie</th>
          <th>Purpose</th>
        </tr>
        <tr>
          <td>Google Analytics</td>
          <td>This is a web analytics service provided by Google Inc which uses cookies to show us how visitors found and explored our site, and how we can enhance their experience. It provides us with information about the behaviour of our visitors (e.g. how long they stayed on our Site, the average number of pages viewed) and also tells us how many visitors we have had.</td>
        </tr>
      </table>
      <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
      <p>You may block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Site.</p>
      <p>Last Updated: February 2017</p>
    </div>
}