import { Link } from "react-router-dom";
import classes from "./privacy.module.css";

export default function Privacy() {
  return <div className={classes.container}>
    <h2>Privacy Policy</h2>
    <p>Henry Cawley Watches a trading name of Unlimited Innovation Ltd offers services for watch related transactions (“Services”) on this website, henrycawleywatches.co.uk (“Website”). In order to provide these Services, we require certain information about you and your watch. This policy sets out the types of personal information we collect and process about you, what we use it for and how we will treat it. Please read this policy carefully and contact us if you have any questions as set out below.</p>
    <h3>Information we process about you</h3>
    <p>When you use our Services we will collect certain information about you, such as:</p>
    <ul>
      <li>Basic contact information you provide to us</li>
      <li>Records of how you use our Services</li>
      <li>Information we receive from third parties (e.g. advertising networks and analytics providers.)</li>
      <li>Records of communications you have with us (e.g. emails, phone calls)</li>
      <li>Technical information about your device and website usage, including cookies (see our Cookies Policy)</li>
    </ul>
    <h3>Contact information you provide to us</h3>
    <p>If you purchase a watch or submit an enquiry or offer, we will collect your name, email address and telephone number and in the case of selling a watch to us, any details of your watch. We will use this information so we can contact you with Offers.</p>
    <h3>Your bank account details</h3>
    <p>In the case we agree to purchase a watch from you, we will request the bank account details for the account into which you wish to receive payment. This includes your name, account number and sort code.</p>
    <h3>Records of how you use our Services</h3>
    <p>We will keep a record of how you use our website, any offers you receive and, in the event of a sale, the price you accept for your watch.</p>
    <h3>Record of communications you have with us</h3>
    <p>If you contact us with a question or query or to provide us with feedback, we will keep a record of your communications with us.</p>
    <h3>Other information you provide to us voluntarily</h3>
    <p>This may include data that you provide us of any feedback that you provide to one of our surveys or focus groups.</p>
    <h3>Technical information about your device and website usage</h3>
    <p>When you use our Website, we collect certain technical information including:</p>
    <ul>
      <li>Details about your device, such as your IP address, device type, operating system and browser</li>
      <li>Information relating to use of the Website, such as the pages you visit, the time spent on each page and the elements of the page you interact with.</li>
    </ul>
    <h3>How we use your personal information</h3>
    <p>We use personal information about you for various purposes connected with our Services, including:</p>
    <ul>
      <li>To facilitate your use of our Services.</li>
      <li>To communicate with you in relation to your use of our Services and provide you with customer support.</li>
      <li>For internal management, administrative and organisational reasons.</li>
      <li>To share information with our suppliers and other third parties in order to provide you with our Services.</li>
      <li>To develop and improve our business, through the use of data analytics and benchmarking.</li>
    </ul>
    <p>In order to use your personal information, we must have a legal basis for doing so. We will only use your personal information where it is necessary:</p>
    <ul>
      <li>To fulfil our contract for services with you</li>
      <li>To comply with a legal obligation to which we are subject</li>
      <li>For our legitimate business interests that are not overridden by your interests, rights and freedoms.</li>
    </ul>
    <p>Where none of the above applies, we will request your consent (which we will ask for before we process the information).</p>
    <h3>Storage of your personal information</h3>
    <p>
      Information about you may be transferred outside the UK to a country, territory or international organisation that may not have UK equivalent data protection standards. 
      <br />
      This may be when we transfer your personal information to our trusted third party service providers (for the purposes described above) outside of the European Union. In such cases, we will ensure that your personal information is protected by implementing appropriate safeguards, such as a European Commission adequacy decision, the EU-US Privacy Shield Certification or the EU Model Contracts, If you would like more information about any of the transfer safeguards on which we rely please contact us as set out in this privacy policy.
    </p>
    <h3>How do we protect your personal information?</h3>
    <p>We implement appropriate technical and organisational measures to protect personal information that we hold from unauthorised disclosure, use, alteration or destruction. Where appropriate, we use encryption and other technologies that can assist in securing the information you provide. We also require our service providers to comply with strict data privacy requirements.</p>
    <h3>How long will your personal information be kept?</h3>
    <p>The period for which we may retain information about you will depend on the purposes for which the information was collected, whether you have requested the deletion of the information, and whether any legal obligations require the retention of it (for example, for regulatory compliance). We will not retain information about you for longer than is necessary to fulfil the purposes for which it was collected.</p>
    <h3>Your rights</h3>
    <p>You may have some or all of the following rights in respect of the information about you that we process:</p>
    <ul>
      <li>request us to give you access to it</li>
      <li>request us to rectify it, update it, or erase it</li>
      <li>request us to restrict our using it, in certain circumstances</li>
      <li>object to our using it, in certain circumstances</li>
      <li>withdraw your consent to our using it</li>
      <li>data portability, in certain circumstances</li>
      <li>opt out from our using it for direct marketing</li>
      <li>lodge a complaint with the Information Commissioner’s Office in the UK which is responsible for upholding your data protection rights.</li>
    </ul>
    <p>You are able to exercise these rights by contacting us through our <Link to="/contact">contact form</Link>.</p>
    <h3>Contact information</h3>
    <p>
      For the purpose of UK data protection law, the controller is Unlimited Innovation Ltd (Company Number 13883827) of 2 Chapel Road, Carleton Rode, Norwich, Norfolk, NR16 1RN.
      <br/>
      If you have any questions, or wish to exercise your rights, please contact us at any time using our <Link to="/contact">contact form</Link>.
    </p>
  </div>
}