import classes from './success.module.css';
import { useState, useEffect } from 'react';
import { getProduct } from '../utils/data';
import { Link } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import { motion, AnimatePresence } from 'framer-motion';

const variants = (direction) => {
  return {
    enter: {
      x: 200 * direction,
      opacity: 0,
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: {
      zIndex: 0,
      x: 200 * direction,
      opacity: 0,
    },
  };
};

export default function Success() {
  const [productId, setProductId] = useState();
  const [orderId, setOrderId] = useState();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setProductId(urlParams.get("product"));
    setOrderId(urlParams.get("order"));
    if (productId) {
      getProduct(productId)
        .then((res) => {
          setProduct(res);
          setLoading(false);
          setError(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  }, [productId]);

  if (loading) {
    return <div><CircularProgress /></div>;
  }

  if (error) {
    return (
      <div className={classes.container}>
        <h1>
          Order received!
        </h1>
        <p>
          It looks as though we're having some connection trouble. But don't
          worry, if your payment went through, then your order will be on it's
          way! If you have any questions don't hesitate to reach out via our{" "}
          <Link to="/contact">contact form</Link>.
        </p>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <AnimatePresence initial={true}>
        <motion.img
          variants={variants(1)}
          initial="enter"
          animate="center"
          exit="exit"
          src={product.mainImage}
          alt={`${product.brand} ${product.model}`}
          className={classes.productImage}
          key={productId}
          drag
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          dragElastic={1}
        />
      </AnimatePresence>
      <AnimatePresence initial={true}>
        <motion.div
          className={classes.textContainer}
          variants={variants(-1)}
          initial="enter"
          animate="center"
          exit="exit"
          key="details"
        >
          <h1>
            Your {product.brand} {product.model} is on its way!
          </h1>
          <h3>Order number</h3>
          <h2>{orderId}</h2>
          <p>
            Thanks for your order! You should receive a confirmation email shortly
            (check your junk!), but just in case please make a note of your order
            number.
          </p>
          <p>
            If you have any questions or special requests for your order, please
            don't hesitate to get in touch with us via our{" "}
            <Link to="/contact">contact form</Link>.
          </p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}