import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export const sendInternalMail = httpsCallable(functions, "sendInternalMail");

export function isValidEmail(input) {
  return input
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
