import { DataContext } from '../app';
import { useContext } from 'react';
import classes from './sold.module.css';

export default function Sold() {
  const data = useContext(DataContext);
  const products = data.products;
  const soldProducts = [];
  Object.keys(products).forEach((key) => {
    if (products[key].sold) {
      soldProducts.push(products[key]);
    }
  });

  const productsHtml = soldProducts.map((product) => {
    return (
      <div className={classes.product}>
        <h1>
          {product.brand.toUpperCase()} {product.model.toUpperCase()}
        </h1>
        <h2>{product.year}</h2>
        <img src={product.mainImage} alt={`${product.brand} ${product.model}`} />
      </div>
    );
  })

  return <div className={classes.container}>
    {productsHtml}
  </div>
}