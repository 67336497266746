import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyBK_2TtGoukU4PwDATlyKP8nQlCf8SZOUM",
  authDomain: "henry-cawley-watches.firebaseapp.com",
  projectId: "henry-cawley-watches",
  storageBucket: "henry-cawley-watches.appspot.com",
  messagingSenderId: "231485626252",
  appId: "1:231485626252:web:f1c35ade81d3b02b42e8a9",
  measurementId: "G-BCY9PW98HY",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default app;
